import React from 'react'
import { Link } from 'gatsby'
import profileNoemi from '../images/profile-noemi.jpeg'
import profileYvonne from '../images/profile-yvonne.jpeg'

import Layout from '../components/layout'

const IntMembersPage = () => (
  <Layout>
    <h2 className="major">International Members</h2>
    <h3 name="Yvonne">Yvonne Maria Sarmiento, Attorney at Law</h3>
    <img width="20%" src={profileYvonne} alt="" />
    <p>
      Yvonne is an Attorney at law in Venezuela, specialized in civil, criminal,
      and commercial law, with emphasis on children and adolescents. Her work
      has been in defense of families and children. She prosecuted a difficult
      case up to the Supreme Court of Venezuela, obtaining custody of a child
      for a father due to the abusive conduct of the mother. Succeeded in a
      number of cases to obtain alimony and/or child support from negligent
      parents. Defended battered women both in civil and criminal courts. Helped
      aging citizens who did not have proper identification papers, recover
      their right to vote in elections.
    </p>
    <h3 name="Noemi">
      Noemi Dargalaye. (E.E.Eng.)  External International Logistics Advisor
    </h3>
    <img width="20%" src={profileNoemi} alt="" />
    <p>
      She graduated as an Electronic Engineer at the Universidad Autónoma
      Metropolitana in Mexico.  She worked with Ericsson Communications
      supporting their installed base in Mexico City.  She then worked for Banca
      Serfin—one of the leading banks of Mexico-- as Manager for the
      communication network of the 350 branches in the Northeast of Mexico. She
      moved to the USA where she did similar work for several technical
      companies in the area of San Diego and Tijuana.  For the last several
      years she has specialized in the management of logistics for international
      cargo to Latin America.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default IntMembersPage
